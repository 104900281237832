import React, { useEffect, useMemo } from 'react'

import 'twin.macro'
import { useLocation } from '@reach/router'
import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useAffiliates from 'content-queries/mx/new/affiliates'
import { validateAffiliateCode } from 'context/project/utils-discounts'
import fireEvent from 'context/tracking/events'
import {
  AffiliatesFooter,
  AffiliatesHero,
  AffiliatesHow,
  TrustedMedia,
} from 'mx/components/new'
import Layout from 'mx/layout'

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search)
}

const OurProcessPage = ({ path }: { path: string }) => {
  const { data } = useAffiliates()
  const queryParams = useQueryParams()
  const affiliateCode = queryParams.get('affiliateCode') || ''

  const validatedAffiliateCode = useMemo(
    () => validateAffiliateCode(affiliateCode),
    [affiliateCode],
  )

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: `Affiliates${
        validatedAffiliateCode?.name ? ' - ' + validatedAffiliateCode.name : ''
      }`,
    })
  }, [validatedAffiliateCode])

  if (!validatedAffiliateCode?.name) {
    return null
  }

  const [
    {
      node: {
        sectionModules: [hero, media, howItWorks, footer],
      },
    },
  ] = data

  return (
    <Layout
      path={path}
      header={false}
      footer={false}
      customBackgroundColor="#f6f5f5"
    >
      <AffiliatesHero
        affiliate={validatedAffiliateCode}
        data={hero}
        classname={tw`mt-20`}
      />
      <TrustedMedia border data={media} classname={tw`mt-20 lg:mt-32`} />
      <AffiliatesHow
        affiliateName={validatedAffiliateCode.shortName}
        data={howItWorks}
        classname={tw`mt-20`}
      />
      <AffiliatesFooter
        affiliateCode={validatedAffiliateCode.affiliateCode}
        data={footer}
        classname={tw`mb-12 max-md:mt-12`}
      />
    </Layout>
  )
}

export default OurProcessPage

export const Head = () => {
  const { data } = useAffiliates()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/affiliates"
      title={title}
      image={
        image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
          ?.src || ''
      }
    />
  )
}
